import axios from 'axios';

export class PaymentParameters { 
    constructor(session) {
      if (session) 
      {
          this.user = session.user;
          this.id_company = session.company;
          this.id_branch = session.branch;
      }
      else 
      {
          this.user = null;
          this.id_company = null;
          this.id_branch = null;
      }
      this.type = 2;
      this.capture = true;
      this.concept = null;
      this.clientReferenceInformation =  {
        code: null,
        // reconciliationId: null,
        // pausedRequestId: null,
        // transactionId: null,
        // comments: null,
        // partner: {
        //   originalTransactionId: null,
        //   developerId: null,
        //   solutionId: null,
        //   thirdPartyCertificationNumber: null
        // },
        // applicationName: null,
        // applicationVersion: null,
        // applicationUser: null
      }
      this.processingInformation = {
        capture: false,
      },
      this.paymentInformation = {
        card: {
          number: null,
          expirationMonth: null,
          expirationYear: null,
          type: null,
          useAs: 'CREDIT',
          securityCode: null,
        },
        // tokenizedCard: {
        //   number: null,
        //   expirationMonth: null,
        //   expirationYear: null,
        //   type: null,
        //   cryptogram: null,
        //   requestorId: null,
        //   transactionType: null,
        //   assuranceLevel: null,
        //   storageMethod: null,
        //   securityCode: null
        // },
        // fluidData: {
        //   keySerialNumber: null,
        //   descriptor: null,
        //   value: null,
        //   encoding: null
        // },
        // customer: {
        //   customerId: null,
        //   id: null
        // },
        // paymentInstrument: {
        //   id: null
        // },
        // instrumentIdentifier: {
        //   id: null
        // },
        // shippingAddress: {
        //   id: null
        // },
        // legacyToken: {
        //   id: null
        // },
        // bank: {
        //   account: {
        //     type: null,
        //     number: null,
        //     encoderId: null,
        //     checkNumber: null,
        //     checkImageReferenceNumber: null
        //   },
        //   routingNumber: null,
        //   iban: null,
        //   swiftCode: null
        // },
        paymentType: {
          name: null,
          is_using_shipping: true,
          is_default: true
          // subTypeName: null,
          // method: {
          //   name: null
          // }
        },
        // initiationChannel: null,
        // eWallet: {
        //   accountId: null
        // }
      },
      this.orderInformationAmountDetails = {
        totalAmount: 0.00,
        currency: 'MXN',
        // subTotalAmount: null,
        // discountAmount: null,
        // dutyAmount: null,
        // gratuityAmount: null,
        // taxAmount: null,
        // nationalTaxIncluded: null,
        // taxAppliedAfterDiscount: null,
        // taxAppliedLevel: null,
        // taxTypeCode: null,
        // freightAmount: null,
        // foreignAmount: null,
        // foreignCurrency: null,
        // exchangeRate: null,
        // exchangeRateTimeStamp: null,
        // surcharge: {
        //   amount: null,
        //   description: null
        // },
        // settlementAmount: null,
        // settlementCurrency: null,
        // amexAdditionalAmounts: [],
        // taxDetails: [],
        // serviceFeeAmount: null,
        // originalAmount: null,
        // originalCurrency: null,
        // cashbackAmount: null,
        // currencyConversion: {
        //   indicator: null,
        //   reconciliationId: null,
        //   id: null
        // }
      },
      this.orderInformationBillTo = {
        name: null,
        firstName: null,
        lastName: null,
        //middleName: null,
        // nameSuffix: null,
        // title: null,
        // company: {
        //   name: null,
        //   address1: null,
        //   address2: null,
        //   locality: null,
        //   administrativeArea: null,
        //   postalCode: null,
        //   country: null
        // },
        address1: null,
        address2: null,
        // address3: null,
        // address4: null,
        locality: null,
        //administrativeArea: null,
        postalCode: null,
        country: null,
        district: null,
        // buildingNumber: null,
        email: null,
        //emailDomain: null,
        phoneNumber: null,
        //phoneType: null
        is_default: true
      },
      this.installmentInformation = {
        amount: "",
        frequency: "",
        planType: null,
        sequence: null,
        totalAmount: null,
        totalCount:null,
        firstInstallmentDate: "",
        invoiceData: "",
        paymentType: "",
        eligibilityInquiry: "",
        gracePeriodDuration: "",
        gracePeriodDurationType: "",
        firstInstallmentAmount: "",
        validationIndicator: "",
        identifier: ""
      }
      this.orderInformationBillToExtra = this.orderInformationBillTo;
    }

    async process() {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/process', this, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }

    async reversal(id, clientReference, amount, comment) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/reversal/' + id, {
        clientReferenceInformationCode: clientReference,
        reversalInformationAmountDetailsTotalAmount: amount,
        reversalInformationInfo: comment
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }

    async refund(id, clientReference, amount, currency) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/refund/' + id, {
        clientReferenceInformationCode: clientReference,
        orderInformationAmountDetailsTotalAmount: amount,
        orderInformationAmountDetailsCurrency: currency
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }

    
    async void(id, clientReference, amount, currency) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/void/' + id, {
        clientReferenceInformationCode: clientReference,
        orderInformationAmountDetailsTotalAmount: amount,
        orderInformationAmountDetailsCurrency: currency
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }


    
    async send(id) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/send/' + id, null, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }

    async captured(id, clientReference, amount, currency) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/capture/' + id, {
        clientReferenceInformationCode: clientReference,
        orderInformationAmountDetailsTotalAmount: amount,
        orderInformationAmountDetailsCurrency: currency
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
    }

    async getTransactions(initial_date, final_date, branch, status,type) {
      branch = branch ?? this.id_branch;
      let response = await axios.post('BusinessWallet/CyberSource/Payment/list', {
        initial_date: initial_date,
        final_date: final_date,
        status: status,
        type:type
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: branch 
          }
      });
      return response.data;
    }

    async getTokenlist(initial_date, final_date, companydb) {
      // branch = branch ?? this.id_branch;
      let response = await axios.post('BusinessWallet/CyberSource/Payment/listtoken', {
        initial_date: initial_date,
        final_date: final_date,
      
       
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch ,
              companydb: companydb
          }
      });
      return response.data;
    }

    async getTransactionsall(initial_date, final_date, branch, status,type) {
      branch = branch ?? this.id_branch;
      let response = await axios.post('BusinessWallet/CyberSource/Payment/TransacionesAll', {
        initial_date: initial_date,
        final_date: final_date,
        status: status,
        type:type
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: branch 
          }
      });
      return response.data;
    }

    async getTransactionId(id) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/listid/'+ id, {
   
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch:   this.id_branch
          }
      });
      return response.data;
    }

    async updateStatus(id,idbill) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/update/'+ id + '/'+ idbill, {

      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch:   this.id_branch
          }
      });
      return response.data;
    }

    async getTransactionsExcel(initial_date, final_date, branch) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/export', {
        initial_date: initial_date,
        final_date: final_date
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: branch ? branch : this.id_branch
          }
      });
      return response.data;
    }

    async processToken(type, body) {
      let response = await axios.post('BusinessWallet/CyberSource/Payment/process/tokenization/' + type, body, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: this.id_branch
          }
      });
      return response.data;
  }
  async companyDB() {
    let response = await axios.get('BusinessWallet/CyberSource/Payment/CompanyBW' , {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch
        }
    });
    return response.data;
}
}